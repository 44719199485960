@import "../../assets/styles";

label > a {
  text-align: center;
  color: blue;
}

.MuiBox-root-32 {
  margin: 0 !important;
}
