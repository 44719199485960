@import "./variables";

.primary-button {
  background-color: $box2home-primary !important;
  color: white !important;
  cursor: pointer !important;
  height: 46px !important;
}

.primary-button:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiDialog-paperWidthSm {
  min-width: 400px !important;
}

html,
html body {
  min-height: 100%;
  font-family: "Century Gothic", sans-serif;
}
