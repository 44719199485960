.form-container {
  display: block;
  max-width: 450px;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
}

input {
  padding-right: 30px !important;
}

.error-text {
  float: left;
  margin: 5px 0;
  color: red !important;
}

span {
  text-align: center;
  width: 100%;
}

.forget-password {
  float: right;
}

.login-error-text {
  text-align: center;
  width: 100%;
  font-size: inherit;
}

.google-button {
  font-size: inherit !important;
}

button {
  background-color: #00bfdd;
  color: white;
}

.MuiButton-textPrimary {
  justify-content: flex-end !important;
  text-transform: none !important;
}

.b2h-logo {
  width: 100%;
}

.login-button {
  background-color: #071e3a !important;
  color: white !important;
}
