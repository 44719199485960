.root {
  flex-grow: 1;
  width: 510px;
  height: 360px;
  text-align: center;
  padding: 16px;
  display: grid;
  font-size: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.button {
  width: fit-content;
  margin: 0 auto !important;
}
